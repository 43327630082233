import {uniqueId} from "lodash";


export default class AccordionClass {

    data = []
    uid
    baseClass = ''
    lineClass = ''

    constructor() {

        this.uid = uniqueId()
    }

    bClass(c) {
        this.baseClass = c;
    }
    lClass(c) {
        this.lineClass = c;
    }

    add(title, content){
        this.data.push({'t': title, 'c': content})
    }

    html(){
        let out = $('<div>').addClass('accordion '+ this.baseClass).attr('id', this.uid)

        $(this.data).each((index, item) => {

            let itemHtml = $('<div>').addClass('accordion-item '+ this.lineClass)
            let head = $('<h2>').addClass('accordion-header')
            let headHtml = $('<button>').addClass('accordion-button')
                .attr({
                    'type': 'button',
                    'data-bs-toggle': 'collapse',
                    'data-bs-target': '#'+this.uid+'-'+index,
                    'aria-controls': this.uid+'-'+index,
                })
                .html(item.t)


            let body = $('<div>').addClass('accordion-collapse collapse')
                .attr({
                    'id': this.uid+'-'+index,
                    'data-bs-parent': 'collapse'

                })
            let bodyHtml = $('<div>').addClass('accordion-body')
                .html(item.c)


            out.append(
                itemHtml.append(
                    head.append(headHtml),
                    body.append(bodyHtml)
                )
            )

        })


        return out

    }
}

